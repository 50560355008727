import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCat } from "../contexts/CatContext";
import { gameLib } from "../lib/gameLib";

export default function Landing() {

  const { step, gameType, setGameType, startNewGame, userId, f, lang, allResults } = useCat();
  const navigate = useNavigate();

  const [selOpen, setSelOpen] = useState(false);

  return (
    step === 0 ? (
    <div className="Landing">
      <div className="game">
        <div className="game__wrapper">
          <h1>{f(`The Word Chain Game`)}</h1>
          <div className="game__intro">
            {f(`Catena is a word game where players make word chains by connecting words while following certain rules.`)}
          </div>
        </div>
        <div className="game__buttons">

          {selOpen && (
            <select
              className="game-type-select"
              onChange={(e) => setGameType(e.target.value)}
            >
              {Object.values(gameLib).map((g, i) => (
                <option key={i} value={g.name}>
                  {f(g.title)}
                </option>
              ))}
            </select>
          )}
          <button className="game-button play splash" onClick={() => {
            if (!userId) {
              navigate('/login');
            } else if (selOpen && gameType) {
              startNewGame(gameType);
            } else {
              setSelOpen(!selOpen);
            }
          }}>
            {selOpen ? f('Start') : f(`Play`)}
          </button>

          {selOpen && (
            <div className="goal-short">
              {gameLib[gameType]?.goal[lang]}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : step === 4 ? (
    <>
      {allResults?.some(ar => ar.score > 0)
        ? <h2>{f('Congratulations! You have scored')} <span className="digits">+{allResults.reduce((acc, ar) => acc + ar.score, 0)}</span> {f('points!')}</h2>
        : <h2>{f('You have scored 0 points. ')} <Link to="/rules">{f('Time to check the rules?')}</Link></h2>
      }
    </>
  ) : null
  )
}