export const rulesVer = 'Rules version: 2.1.0';

export const oaiChatEndpoint = 'https://api.openai.com/v1/chat/completions';
export const tokenEndpoint = 'https://catena.martlet.live/wp-json/jwt-auth/v1/token';
export const userEndpoint = 'https://catena.martlet.live/wp-json/wp/v2/users';
export const profilesEndpoint = 'https://catena.martlet.live/wp-json/wp/v2/score';
export const chainsEndpoint = 'https://catena.martlet.live/wp-json/wp/v2/chain';
export const addChainsEndpoint = 'https://catena.martlet.live/wp-json/catena/v1/add-chains';
export const picsEndpoint = 'https://catena.martlet.live/wp-json/wp/v2/pic?per_page=100&_embed&acf';
export const mediaEndpoint = 'https://catena.martlet.live/wp-json/wp/v2/media';


export const reqOptionsGet = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
}

export const reqOptionsPost = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa('app-rest-user:' + process.env.REACT_APP_WP_PASS)}`,
  }
}

