import React from "react";
import ReactMarkdown from 'react-markdown';
import Layout from '../Layout';
import Banner from "./Banner";
import { useCat } from "../contexts/CatContext";
import './Static.scss';

export default function Uses() {

  const {use} = useCat();

  return (
    <Layout>
      <Banner />
      <div className="static">
        <ReactMarkdown>{use}</ReactMarkdown>
      </div>
    </Layout>
  )
}
