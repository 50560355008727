export const pairs_en = [
  ['gate', 'guitar'],
  ['door', 'music'],
  ['rain', 'statue'],
  ['cake', 'light'],
  ['market', 'fog'],
  ['horse', 'dice'],
  ['gate', 'guitar'],
  ['door', 'music'],
  ['library', 'rainbow'],
  ['winter', 'lion'],
  ['ocean', 'puzzle'],
  ['clock', 'garden'],
  ['mountain', 'music'],
  ['dream', 'bridge'],
  ['mirror', 'forest'],
  ['castle', 'shadow'],
  ['key', 'comet'],
  ['cloud', 'festival']
];

export const pairs_ru = [
  ['ворота', 'гитара'],
  ['дверь', 'музыка'],
  ['дождь', 'статуя'],
  ['торт', 'свет'],
  ['рынок', 'туман'],
  ['лошадь', 'кубик'],
  ['дверь', 'музыка'],
  ['библиотека', 'радуга'],
  ['зима', 'лев'],
  ['океан', 'головоломка'],
  ['часы', 'сад'],
  ['гора', 'музыка'],
  ['сон', 'мост'],
  ['зеркало', 'лес'],
  ['замок', 'тень'],
  ['ключ', 'комета'],
  ['облако', 'фестиваль']
];

export const pairs_cs = [
  ['brána', 'kytara'],
  ['dveře', 'hudba'],
  ['déšť', 'socha'],
  ['koláč', 'světlo'],
  ['trh', 'mlha'],
  ['kůň', 'kostky'],
  ['dveře', 'hudba'],
  ['knihovna', 'duha'],
  ['zima', 'lev'],
  ['oceán', 'hlava'],
  ['hodiny', 'zahrada'],
  ['hora', 'hudba'],
  ['sen', 'most'],
  ['zrcadlo', 'les'],
  ['hrad', 'stín'],
  ['klíč', 'kometa'],
  ['mrak', 'festival']
];

export const pairs_vn = [
  ['cổng', 'đàn ghi ta'],
  ['cửa', 'âm nhạc'],
  ['mưa', 'tượng'],
  ['bánh', 'ánh sáng'],
  ['chợ', 'sương mù'],
  ['ngựa', 'xúc xắc'],
  ['cửa', 'âm nhạc'],
  ['thư viện', 'cầu vồng'],
  ['mùa đông', 'sư tử'],
  ['đại dương', 'trò chơi ghép hình'],
  ['đồng hồ', 'vườn'],
  ['núi', 'âm nhạc'],
  ['mơ', 'cây cầu'],
  ['gương', 'rừng'],
  ['lâu đài', 'bóng tối'],
  ['chìa khóa', 'sao chổi'],
  ['mây', 'lễ hội']
];

