import React from "react";
import { uid } from "uid";
import { gameLib } from "../lib/gameLib";
import { useCat } from "../contexts/CatContext";

export default function ChainsetView() {

  const { chainset, gameType, processChainset, setChain, setChainset, step, setStep, reset, allResults, f } = useCat();

  const editChain = (i) => {
    if (step === 3) {
      setStep(2);
    }
    setChain(chainset[i]);
    setChainset(chainset.filter((_, idx) => idx !== i));
  }

  return (
    [2, 3, 4].includes(step) && (

      <div className="chainset-review">
        {[2, 3].includes(step) && (
          <>
            <h2>{f('Chainset Review')}</h2>

            <div className="chainset">
              {chainset?.map((ch, i) => (
                <div className="chain__wrapper" key={uid()}>
                  <div className="chain__header">{i + 1}</div>

                  <div className="chain">
                    {ch.map((word, idx) => (
                      <span className="chain__cell" key={idx}>
                        <span className="word">
                          {word} {idx !== (ch.length - 1) ? <span className="space">{'\u2022 '}</span> : ''}
                        </span>
                      </span>
                    ))}
                  </div>
                  <div className="ud-buttons" key={uid()}>
                    <i className="material-symbols-outlined icon-button edit"
                      onClick={() => editChain(i)}>
                      edit
                    </i>
                  </div>

                </div>
              ))}

              {chainset.length < gameLib[gameType].chainsetLen && (
                new Array(gameLib[gameType].chainsetLen - chainset.length).fill(0).map((_, i) => (
                  <div className="chain__wrapper" key={uid()}>
                    <div className="chain__header">{chainset.length + i + 1}</div>

                    <div className="chain">

                    </div>
                  </div>
                )))}
            </div>
            {step === 3 &&
              <button className="game-button send splash" onClick={processChainset}>{f('Submit')}</button>
            }
          </>
        )}


        {step === 4 && (
          <>
            <h2>{f('Game Results')}</h2>
            <div className="chainset">
              {allResults?.map((ar, i) => (

                <div className="chain__wrapper" key={uid()}>
                  <div className="chain__header">{i + 1}</div>

                  <div className="chain">
                    {ar.chainAsString}
                  </div>

                  <div className="chain__score">
                    {ar.score}
                  </div>

                  {ar.explanation &&
                    <div className="chain__explanation">
                      {ar.explanation}
                    </div>
                  }

                </div>
              ))}


              <div className="flex-row-center" key={uid()}>
                <button className="game-button play splash" onClick={reset}>
                  {f('New Game')}
                </button>
              </div>

            </div>
          </>
        )}

      </div>
    )
  )
}




