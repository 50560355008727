import React, { useEffect } from "react";
import Layout from '../Layout';
import { useCat } from "../contexts/CatContext";
import Wrapper from "./Wrapper";
import Neono from "./Neono";
import Slider from "./Slider";
import ChainsetView from "./ChainsetView";
import ChainView from "./ChainView";
import Landing from "./Landing";
import Intro from "./Intro";
import './Front.scss';

export default function Front() {

  const { loading, reset } = useCat();
  


  useEffect(() => {
    // // console.log('resetting');
    reset();
  }, []);
    

  return (
    <Layout>
      <div className={`Front`}>
        <Wrapper className="prima">
          <Landing />
          <ChainView />
          <Slider />
        </Wrapper>
        
        <Wrapper className="secunda">
          {loading && <Neono />}
          <Intro />
          <ChainsetView />
        </Wrapper>
      </div>
    </Layout>
  )
}

// step variable is used to control the flow of the game
// step 0: initial state, no game started
// step 1: game started, no chains created
// step 2: some chains created
// step 3: chainset is full. not submitted yet
// step 4: chainset submitted, processed, score shown
