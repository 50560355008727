export const laNames = {
  en: 'English',
  ru: 'Русский',
  cs: 'Česky',
  vn: 'Tiếng Việt',
}

export const pairs = [
  ['gate', 'guitar'],
  ['door', 'music'],
  ['rain', 'statue'],
  ['cake', 'light'],
  ['market', 'fog'],
  ['horse', 'dice'],
  ['gate', 'guitar'],
  ['door', 'music'],
  ['Library', 'Rainbow'],
  ['Winter', 'Lion'],
  ['Ocean', 'Puzzle'],
  ['Clock', 'Garden'],
  ['Mountain', 'Music'],
  ['Dream', 'Bridge'],
  ['Mirror', 'Forest'],
  ['Castle', 'Shadow'],
  ['Key', 'Comet'],
  ['Cloud', 'Festival']];

export const pairTranslation = {
  en: [
    { a: 'gate' },
    { b: 'guitar' },
    { c: 'door' },
    { d: 'music' },
    { e: 'rain' },
    { f: 'statue' },
    { g: 'cake' },
    { h: 'sun' },
    { i: 'market' },
    { j: 'fog' },
    { k: 'horse' },
    { l: 'dice' },
    { m: 'Library' },
    { n: 'Rainbow' },
    { o: 'Winter' },
    { p: 'Lion' },
    { q: 'Ocean' },
    { r: 'Puzzle' },
    { s: 'Clock' },
    { t: 'Garden' },
    { u: 'Mountain' },
    { v: 'Music' },
    { w: 'Dream' },
    { x: 'Bridge' },
    { y: 'Mirror' },
    { z: 'Forest' },
    { aa: 'Castle' },
    { ab: 'Shadow' },
    { ac: 'Key' },
    { ad: 'Comet' },
    { ae: 'Cloud' },
    { af: 'Festival' },
  ],
  ru: [
    { a: 'ворота' },
    { b: 'гитара' },
    { c: 'дверь' },
    { d: 'музыка' },
    { e: 'дождь' },
    { f: 'статуя' },
    { g: 'торт' },
    { h: 'свет' },
    { i: 'рынок' },
    { j: 'туман' },
    { k: 'лошадь' },
    { l: 'кубики' },
    { m: 'библиотека' },
    { n: 'радуга' },
    { o: 'зима' },
    { p: 'лев' },
    { q: 'океан' },
    { r: 'головоломка' },
    { s: 'часы' },
    { t: 'сад' },
    { u: 'гора' },
    { v: 'музыка' },
    { w: 'сон' },
    { x: 'мост' },
    { y: 'зеркало' },
    { z: 'лес' },
    { aa: 'замок' },
    { ab: 'тень' },
    { ac: 'ключ' },
    { ad: 'комета' },
    { ae: 'облако' },
    { af: 'фестиваль' },
  ],
  cs: [
    { a: 'brána' },
    { b: 'kytara' },
    { c: 'dveře' },
    { d: 'hudba' },
    { e: 'déšť' },
    { f: 'socha' },
    { g: 'koláč' },
    { h: 'slunce' },
    { i: 'trh' },
    { j: 'mlha' },
    { k: 'kůň' },
    { l: 'kostky' },
    { m: 'knihovna' },
    { n: 'duha' },
    { o: 'zima' },
    { p: 'lev' },
    { q: 'oceán' },
    { r: 'puzzle' },
    { s: 'hodiny' },
    { t: 'zahrada' },
    { u: 'hora' },
    { v: 'hudba' },
    { w: 'sen' },
    { x: 'sen' },
    { y: 'most' },
    { z: 'zrcadlo' },
    { aa: 'les' },
    { ab: 'hrad' },
    { ac: 'stín' },
    { ad: 'klíč' },
    { ae: 'kometa' },
    { af: 'oblak' },
    { ag: 'festival' },
  ],
  vn: [
    { a: 'cổng' },
    { b: 'đàn ghi ta' },
    { c: 'cửa' },
    { d: 'âm nhạc' },
    { e: 'mưa' },
    { f: 'tượng' },
    { g: 'bánh' },
    { h: 'ánh sáng' },
    { i: 'chợ' },
    { j: 'sương mù' },
    { k: 'ngựa' },
    { l: 'súc sắc' },
    { m: 'thư viện' },
    { n: 'cầu vồng' },
    { o: 'mùa đông' },
    { p: 'sư tử' },
    { q: 'đại dương' },
    { r: 'trò chơi ghép hình' },
    { s: 'đồng hồ' },
    { t: 'vườn' },
    { u: 'núi' },
    { v: 'âm nhạc' },
    { w: 'giấc mơ' },
    { x: 'cây cầu' },
    { y: 'gương' },
    { z: 'rừng' },
    { aa: 'lâu đài' },
    { ab: 'bóng tối' },
    { ac: 'chìa khóa' },
    { ad: 'sao chổi' },
    { ae: 'mây' },
    { af: 'lễ hội' },
  ]
};

export const front = {
  en: [
    { a: 'Play Now' },
    { b: 'Login to Play' },
    { c: 'Create Word Chains' },
    { d: 'Start playing by connecting the first and the last words with related words' },
    { e: 'My Chains' },
    { f: 'Done' },
    { g: 'Submit My Chains' },
    { h: 'New Game' },
    { i: 'Add word and press Enter' },
    { j: 'Score' },
    { k: 'Chain must be at least 3 words long' },
    { l: 'The Word Chain Game' },
    { m: `Catena is a word game where players make word chains by connecting words while following certain rules.`},
    { n: 'Rules' },
    { o: `Add words to the chain by clicking the plus sign between words.` },
    { p: 'Total Score' },
    { q: 'Latest Score' },
    { r: 'Games Played' },
    { s: 'Only English aphabet letters are allowed in this game session. ' },
    { t: 'Explanations' },
    { u: 'Ready to Send?' },
    { v: 'Game Goal:' },
    { w: 'Create one chain.' },
    { x: 'Full Rules' },
    { y: 'You cannot change language when the game is in progress. ' },
    { z: 'Start Afresh' },
    { aa: 'Play' },
    { ab: 'Start'},
    { ac: 'Submit' },
    { ad: 'Home' },
    { ae: 'Applications' },
    { af: 'To play in a different language, press "Start Afresh", then choose your desired language and start a new game.' },
    { ag: 'Login' },
    { ah: 'Logout' },
    { ai: 'Dashboard' },
    { aj: 'Chainset' },
    { ak: 'How to Play' },
    { al: 'Uses' },
    { am: 'Login / Register' },
  ],
  ru: [
    { a: 'Играть' },
    { b: 'Войдите, чтобы начать' },
    { c: 'Создаем цепочки слов' },
    { d: 'Начните играть, соединяя первое и последнее слова связанными с ними словами' },
    { e: 'Мои цепочки' },
    { f: 'Готово' },
    { g: 'Отправить цепочки' },
    { h: 'Новая игра' },
    { i: 'Добавить слово и нажать Enter' },
    { j: 'Баллов' },
    { k: 'Цепочка должна состоять как минимум из 3 слов' },
    { l: 'Здесь играют в Катену' },
    { m: 'Catena - это словесная игра, в которой игроки создают цепочки слов, соединяя слова в соответствии с заданными правилами.' },
    { n: 'Правила' },
    { o: 'Добавляйте слова в цепочку, нажимая на плюс между словами.' },
    { p: 'Всего баллов' },
    { q: 'Баллов за игру' },
    { r: 'Игр' },
    { s: 'В текущей игре можно использовать только русский алфавит. Чтобы играть на другом языке, переключитесь на нужный язык и начните новую игру' },
    { t: 'Пояснения' },
    { u: 'Все готово?' },
    { v: 'Цель игры:' },
    { w: 'Создать одну цепочку.' },
    { x: 'Полная версия правил' },
    { y: 'Нельзя менять язык, когда игра уже началась. Нажмите "Начать сначала", чтобы начать игру на другом языке' },
    { z: 'Начать сначала' },
    { aa: 'Играть' },
    { ab: 'Начать'},
    { ac: 'Отправить' },
    { ad: 'Главная' },
    { ae: 'Применения' },
    { af: 'Чтобы играть на другом языке, нажмите "Начать сначала", затем выберите нужный язык и начните новую игру.' },
    { ag: 'Войти' },
    { ah: 'Выйти' },
    { ai: 'Панель' },
    { aj: 'Чейнсет' },
    { ak: 'Учимся играть' },
    { al: 'Применения' },
    { am: 'Вход / Регистрация' },
  ],
  cs: [
    { a: 'Začněte hrát' },
    { b: 'Přihlaste se k hraní' },
    { c: 'Vytváříme řetězece slov' },
    { d: 'Začněte spojovat první a poslední slova souvisejícími slovy' },
    { e: 'Moje řetězce' },
    { f: 'Hotovo' },
    { g: 'Odeslat řetězce' },
    { h: 'Nová hra' },
    { i: 'Přidejte slovo a stiskněte Enter' },
    { j: 'Баллов' },
    { k: 'Řetězec musí mít alespoň 3 slova' },
    { l: 'Hrajeme na slovní řetězce' },
    { m: `Catena je slovní hra, ve které hráči vytvářejí řetězce slov, spojujíce slova podle určitých pravidel.` },
    { o: 'Přidejte slova do řetězce kliknutím na plus mezi slovy.' },
    { p: 'Celkové skóre' },
    { q: 'Skóre hry' },
    { r: 'Hry odehráno' },
    { s: 'V probíhaiící hře jsou povoleny pouze anglická slova. Chcete-li hrát v jiném jazyce, přepněte na ten jazyk a začněte novou hru' },
    { t: 'Vysvětlení' },
    { u: 'Jste připraveni?' },
    { v: 'Cíl hry:' },
    { w: 'Vytvořit jeden řetězec.' },
    { x: 'Celá pravidla' },
    { y: 'Bohužel nejde měnit jazyk, když je hra v průběhu. Pokud chcete začít novou hru v jiném jazyce, stiskněte "Začít znovu".' },
    { z: 'Začít znovu' },
    { aa: 'Hrát' },
    { ab: 'Začít'},
    { ac: 'Odeslat' },
    { ad: 'Domů' },
    { ae: 'Použití' },
    { af: 'Chcete-li hrát v jiném jazyce, stiskněte "Začít znovu", zvolte požadovaný jazyk a začněte novou hru.' },
    { ag: 'Přihlásit se' },
    { ah: 'Odhlásit se' },
    { ai: 'Přehled' },
    { aj: 'Řetězce' },
    { ak: 'Jak hrát' },
    { al: 'Použití' },
    { am: 'Přihlášení / Registrace' },
  ],
  vn: [
    { a: 'Bắt đầu chơi' },
    { b: 'Đăng nhập để chơi' },
    { c: 'Tạo chuỗi từ' },
    { d: 'Bắt đầu chơi bằng cách kết nối các từ đầu tiên và cuối cùng với các từ liên quan' },
    { e: 'Chuỗi của tôi' },
    { f: 'Xong' },
    { g: 'Gửi chuỗi của tôi' },
    { h: 'Chơi lại' },
    { i: 'Thêm từ và nhấn Enter' },
    { j: 'Điểm' },
    { k: 'Chuỗi phải có ít nhất 3 từ' },
    { l: 'Trò chơi chuỗi từ' },
    { m: 'Catena là một trò chơi từ trong đó người chơi tạo chuỗi từ bằng cách kết nối các từ theo các quy tắc nhất định.' },
    { n: 'Luật chơi' },
    { o: 'Thêm từ vào chuỗi bằng cách nhấp vào dấu cộng giữa các từ.' },
    { p: 'Tổng điểm' },
    { q: 'Điểm mới nhất' },
    { r: 'Trò chơi đã chơi' },
    { s: 'Chỉ các từ tiếng Anh được phép trong phiên chơi này. ' },
    { t: 'Giải thích' },
    { u: 'Sẵn sàng gửi?' },
    { v: 'Mục tiêu trò chơi:' },
    { w: 'Tạo một chuỗi.' },
    { x: 'Luật chơi đầy đủ' },
    { y: 'Bạn không thể thay đổi ngôn ngữ khi trò chơi đang diễn ra. ' },
    { z: 'Chơi lại' },
    { aa: 'Chơi' },
    { ab: 'Bắt đầu'},
    { ac: 'Gửi' },
    { ad: 'Trang chủ' },
    { ae: 'Ứng dụng' },
    { af: 'Để chơi bằng ngôn ngữ khác, nhấn "Chơi lại", sau đó chọn ngôn ngữ bạn muốn và bắt đầu một trò chơi mới.' },
    { ag: 'Đăng nhập' },
    { ah: 'Đăng xuất' },
    { ai: 'Bảng điều khiển' },
    { aj: 'Chuỗi' },
    { ak: 'Hướng dẫn chơi' },
    { al: 'Ứng dụng' },
    { am: 'Đăng nhập / Đăng ký' },
  ]
};


export const score_words = {
  en: {
    total_score: 'Total Score',
    total_chains: 'Total Chains',
    total_games: 'Total Games',
    nick: 'Nickname',
  },
  ru: {
    total_score: 'Всего баллов',
    total_chains: 'Всего цепочек',
    total_games: 'Всего игр',
    nick: 'Никнейм',
  },
  cs: {
    total_score: 'Celkem bodů',
    total_chains: 'Celkem řetězců',
    total_games: 'Celkem her',
    nick: 'Přezdívka',
  },
  vn: {
    total_score: 'Tổng số điểm',
    total_chains: 'Tổng số chuỗi',
    total_games: 'Tổng số trò chơi',
    nick: 'Tên đăng nhập',
  },
};

