import React from "react";


export default function SelectAvatar({ pictures, setSelectedImage }) {

  const onImageSelect = (pic) => {
    const image = new Image();
    image.src = pic.url;
    image.onload = () => {
      setSelectedImage({ ...pic });
    };
    // console.log('p3', pictures);
  };

  return (
    pictures?.length > 0 && (
      <div className="avatar-select">
        {pictures.map((pic, i) => (
          <div
            id={pic.id}
            key={i}
            className={`pic`}
            onClick={() => onImageSelect(pic)}
          >
            <img
              src={pic.url}
              alt=""
              width={75}
              height={75}
            />
          </div>
        ))}
      </div>
    )
  )
}