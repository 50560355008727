import React from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Bar from "./components/Bar";


export default function Layout({ children, className='' }) {

const location = useLocation();

  return (
    <div className={`page${' '+ className}`}>
      {location.pathname !== '/login' && <Bar />}
      <ToastContainer />
       {children}
    </div>
  )
}
