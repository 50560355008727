export const catena_front_en = `## How to Play

The player's objective is to form a chain of words, connecting each word to the next with one of two main types of links defined by the game's rules: Context Links and Analogy Links.

Context Links are based on typical scenarios of word use. For example, Coffee – Morning or Book – Library are Context Links, drawing from everyday life experiences.

Analogy Links, on the contrary, are based on mind's ability to find similarities between seemingly unrelated things. For example, Snail – Galaxy or Obstacle – Wall are Analogy Links, where you draw parallels or symbolic resemblances.

## Scoring

- Points are awarded for the brevity of your catena, and for the use of words with multiple meanings.
- Penalties are given for weak or unclear connections.
- The assessment of players's entries is being done by the AI model. 

Keep your catenas to a maximum of 7 words and use only singular nouns.

So, get ready to stretch your imagination, dive into the depths of language, and have fun  with Catena!`;

export  const catena_front_ru = `### Как играть

Задача игрока — придумать цепочку слов, где каждое слово соединено с последующим одним из типов связей, принятых в игре, а именно либо по контексту, либо по аналогии.

Связи по контексту основаны на типичных житейских сценариях: например, "Кофе – Утро" или "Книга – Библиотека".

Связи по аналогии, напротив, способствуют креативному, нестандартному подходу — они основаны на сходствах между, казалось бы, не связанными словами, например, "Улитка – Галактика" или "Препятствие – Стена".

### Оценка

- Баллы начисляются за краткость цепочки и за использование слов с несколькими значениями.
- Штрафы начисляются за слабые или неясные связи.
- Оценка соответствия правилам производится ИИ.

Максимальная допустимая длина цепочки — 7 слов. Используйте только однословные существительные в именительном падеже единственного числа.

Желаем вам весело провести время с Катеной!`;


export const catena_front_cs = `### Jak hrát

Cílem hráče je vytvořit řetězec slov, ve kterém každé slovo je spojeno s následujícím jedním ze dvou typů vazeb definovaných pravidly hry, což jsou kontextové a analogické vazby.

Kontextové vazby jsou založeny na typických scénářích, souvicejicích se slovem. Například "Káva – Ráno" nebo "Kniha – Knihovna" jsou kontextové vazby, vycházející ze zkušeností každodenního života gráčů.

Analogické vazby naopak vyžadují kreativní, nekonvenční přístup. Tato spojení jsou založena na podobnostech mezi zdánlivě nesouvisejícími slovy. Například "Šnek – Galaxie" nebo "Překážka – Zeď" jsou analogické vazby, kde nacházíte paralely nebo symbolické podobnosti.

Maximální délka řetězce je 7 slov a musí to být podstatná jména v jednotném čísle. 

### Bodování

- Body se udělují za stručnost řetězce a za použití slov s více významy.
- Tresty jsou udělovány za slabé nebo nejasné vazby.
- Hodnocení hráčů provádí umělá inteligence.¨

Takže se připravte rozvíjet svou představivost, ponořte se do hlubin jazyka a náramně se bavte!`;

export const catena_front_vn = `### Cách chơi

Mục tiêu của người chơi là tạo ra một chuỗi từ, trong đó mỗi từ được kết nối với từ tiếp theo bằng một trong hai loại liên kết chính được xác định bởi luật chơi: Liên kết Ngữ cảnh và Liên kết Tương tự.

Liên kết Ngữ cảnh dựa trên các kịch bản sử dụng từ điển hình. Ví dụ, Cà phê - Buổi sáng hoặc Sách - Thư viện là Liên kết Ngữ cảnh, lấy cảm hứng từ trải nghiệm cuộc sống hàng ngày.

Liên kết Tương tự, ngược lại, dựa trên khả năng của tâm trí để tìm thấy sự tương đồng giữa những thứ dường như không liên quan. Ví dụ, Ốc sên - Ngân hà hoặc Chướng ngại vật - Tường là Liên kết Tương tự, nơi bạn vẽ ra những đường song song hoặc sự tương đồng tượng trưng.

### Điểm số

- Điểm được trao cho sự ngắn gọn của chuỗi từ của bạn và cho việc sử dụng các từ có nhiều ý nghĩa.
- Trừ điểm cho các liên kết yếu hoặc không rõ ràng.
- Đánh giá các mục của người chơi được thực hiện bởi mô hình AI.`;



