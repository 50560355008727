export const rules_en = `## Catena: A Creative Word Game
  
**Catena challenges players to develop creative and critical thinking by forming the shortest valid chains of words. 
Players earn points for their chains, aiming to achieve the highest score within a defined number of chains and time.**

**Basic Rules**

- Limit each catena (word chain) to a maximum of 7 words.
- Use only singular nouns.

### Allowed Link Types

Players connect words using the two defined link types - context links and analogy links. 
This limitation aims to enhance the game's challenge and allow for meaningful evaluation and comparison of chains.

**Context Links**: Connect words based on typical situations, common scenarios, and frequent real-life uses. Examples: 

- Morning – Coffee
- Birthday – Cake
- Weather – Forecast

**Analogy Links**: Connect words based on noticeable similarities in form, function, or symbolism.  Examples: 

- Form: Snail – Galaxy
- Function: Rain – Fountain
- Symbolism: Obstacle – Wall
   
### Disallowed Link Types

If we allowed these types of connections, the game would be too easy: e.g. any two nouns can be connected through the word "something" as every thing is something. 

- Analytical Connections: classifications, part-to-whole, made-of connections are not permitted. Examples: Human - Mammal - Bear, or Human - Brain - Evolution, or Human - Water - Ocean.
- Empirical Connections: Connections based on personal experiences are disallowed. Examples: Car - Mercedes - Office (if the player works in an office and drives a Mercedes).
- Sound/Spelling Connections: Avoid connections based solely on rhyming or similar spellings. Example: Cat - Hat - Bat.
- Niche Cultural References: Avoid connections based solely on specific books, movies, memes, or slang, however popular at the moment. Example: Stick - Harry Potter - Magic.

### Scoring

Shorter catenas score higher. Begin with 10 points per catena; deduct 2 points for each word beyond the third.

The game's results are evaluated by artificial intelligence. To avoid disputes, players agree not to challenge the validity of the chain as evaluated by the AI during a specific game, even if the evaluation is controversial or erroneous, changes over time, or varies among different players.
     
Enjoy the game!`;

  

export const rules_ru = `## Катенв: творческая словесная игра

**Катена помогает игрокам развивать творческое и критическое мышление, формируя максимально короткие цепочки слов, связанных между собой определенным обрвзом согласно правилам.

Игроки получают очки за валидные цепочки, стремясь заработать максимум очков в отведенное время при заданном количестве цепочек и исходншых пар слов.**


**Основные правила**

- Максимальная длина цепочки — 7 слов.
- Используйте только существительные в единственном числе.

### Разрешенные типы связей

Игроки соединяют слова, используя два типа связей: связи по контексту и связи по аналогии.

**Связи  по контексту**: Соединяйте слова, основываясь на типичных ситуациях, общих сценариях и частых реальных применениях. Примеры:

- Утро – Кофе
- День рождения – Торт
- Погода – Прогноз

**Связи по аналогии**: Соединяйте слова, основываясь на заметных сходствах в форме, функции или символике. Примеры:

- Форма: Улитка – Галактика
- Функция: Дождь – Фонтан
- Символика: Препятствие – Стена

### Запрещенные типы связей

Если бы мы разрешили эти типы связей, игра была бы слишком простой: например, любые два существительных можно было бы соединить словом «что-то», так как все вещи являются чем-то.

- Аналитические связи: классификации, часть-целое, связи типа «сделано из» не допускаются. Примеры: Человек – Млекопитающее – Медведь, или Человек – Мозг – Эволюция, или Человек – Вода – Океан. 
- Эмпирические связи: Связи, основанные на личном опыте, запрещены. Примеры: Машина – Мерседес – Офис (если игрок работает в офисе и ездит на Мерседесе).
- Звуковые/орфографические связи: Избегайте связей, основанных исключительно на рифме или похожей орфографии. Пример: Кот – Рот – Ход.
- Ссылки на нишевую культуру: Избегайте связей, основанных исключительно на конкретных книгах, фильмах, мемах или сленге. Пример: Палочка – Гарри Поттер – Магия.

### Оценка 

Короткие цепочки оцениваются выше. Начните с 10 очков за каждую цепочку; вычтите 2 очка за каждое слово после третьего.

Результаты игры оценивает искусстыенный интелллект. Во избежание разногласий игроки обязуются не оспаривать оценку валидности цепочки, даннуе ИИ в ходе конкретной игры, даже если эта оценка является спорной или ошибочной, меняется со временем или вариьируется у разных игроков.

Наслаждайтесь игрой!`;


export const rules_cs= `## Catena: Kreativní slovní hra

**Catena vyzývá hráče, aby rozvíjeli kreativní a kritické myšlení vytvářením řetězce slov dle zadaných pravidel.

Hráči získávají body za platné řetězce, snaží se získat co nejvíce bodů v daném čase při zadaném počtu řetězců a počátečních slovních dvojic.**

**Základní pravidla**

- Maximální délka řetězce je 7 slov.
- Používejte pouze podstatná jména v jednotném čísle.

### Povolené typy spojení

Hráči spojují slova pomocí dvou definovaných typů spojení - spojení kontextu a spojení analogie.

**Spojení kontextu**: Spojte slova na základě typických situací, běžných scénářů a častého reálného použití. Příklady:

- Ráno - Káva
- Narozeniny - Koláč
- Počasí - Předpověď

**Spojení analogie**: Spojte slova na základě zřejmých podobností ve formě, funkci nebo symbolice. Příklady:

- Forma: Hlemýžď - Galaxie
- Funkce: Déšť - Fontána
- Symbolika: Překážka - Zeď

### Nepovolené typy spojení

Pokud bychom tyto typy spojení povolili, hra by byla příliš jednoduchá: například jakékoliv dvě podstatná jména by bylo možné spojit slovem „něco“, protože všechny věci jsou něco.

- Analytická spojení: klasifikace, část-část, spojení typu „vyrobeno z“ nejsou povolena. Příklady: Člověk - Savci - Medvěd, nebo Člověk - Mozek - Evoluce, nebo Člověk - Voda - Oceán.
- Empirická spojení: spojení založená na osobních zkušenostech nejsou povolena. Příklady: Auto - Mercedes - Kancelář (pokud hráč pracuje v kanceláři a jezdí Mercedesem).
- Zvuková / pravopisná spojení: Vyhněte se spojením založeným pouze na rýmování nebo podobném pravopisu. Příklad: Kočka - Vločka - Čočka.
- Odkazy na kulturní náplň: Vyhněte se spojením založeným pouze na konkrétních knihách, filmech, memech nebo slangových výrazech. Příklad: Hůlka - Harry Potter - Magie.

### Skórování a hraní

Kratší řetězce získávají více bodů. Začněte s 10 body za každý řetězec; odečtěte 2 body za každé slovo po třetím.

Užijte si hru!`;

export const rules_vn = `## Catena: Trò chơi từ sáng tạo

**Catena thách thức người chơi phát triển tư duy sáng tạo và tư duy phản biện bằng cách tạo ra chuỗi từ ngắn nhất hợp lệ.
Người chơi kiếm điểm cho chuỗi của họ, nhằm đạt được số điểm cao nhất trong một số chuỗi và thời gian xác định.**

**Quy tắc cơ bản**

- Giới hạn mỗi chuỗi từ (word chain) tối đa 7 từ.
- Chỉ sử dụng danh từ số ít.

### Loại liên kết được phép

Người chơi kết nối các từ bằng hai loại liên kết được xác định - liên kết ngữ cảnh và liên kết tương tự.
Giới hạn này nhằm tăng cường thử thách của trò chơi và cho phép đánh giá và so sánh chuỗi có ý nghĩa.

**Liên kết ngữ cảnh**: Kết nối các từ dựa trên các tình huống điển hình, kịch bản phổ biến và sử dụng thực tế thường xuyên. Ví dụ:

- Buổi sáng - Cà phê
- Sinh nhật - Bánh
- Thời tiết - Dự báo

**Liên kết tương tự**: Kết nối các từ dựa trên sự tương đồng rõ ràng về hình thức, chức năng hoặc biểu tượng. Ví dụ:

- Hình thức: Ốc sên - Ngân hà
- Chức năng: Mưa - Đài phun nước

### Loại liên kết không được phép

Nếu chúng tôi cho phép các loại kết nối này, trò chơi sẽ quá dễ: ví dụ, bất kỳ hai danh từ nào cũng có thể được kết nối thông qua từ "cái gì đó" vì mọi thứ đều là cái gì đó.

- Kết nối phân tích: phân loại, phần-to-toàn bộ, kết nối được làm từ không được phép. Ví dụ: Con người - Thú vật - Gấu, hoặc Con người - Não bộ - Tiến hóa, hoặc Con người - Nước - Đại dương.
- Kết nối kinh nghiệm: Không được phép kết nối dựa trên kinh nghiệm cá nhân. Ví dụ: Xe hơi - Mercedes - Văn phòng (nếu người chơi làm việc trong văn phòng và lái xe Mercedes).
- Kết nối âm thanh / chính tả: Tránh kết nối dựa trên việc vần hoặc chính tả giống nhau. Ví dụ: Mèo - Mũ - Dơi.
- Tham chiếu văn hóa chuyên môn: Tránh kết nối dựa trên sách, phim, meme hoặc tiếng lóng cụ thể, tuy nhiên phổ biến vào thời điểm này. Ví dụ: Gậy - Harry Potter - Ma thuật.

### Điểm và lối chơi

Chuỗi ngắn hơn được tính điểm cao hơn. Bắt đầu với 10 điểm cho mỗi chuỗi; trừ 2 điểm cho mỗi từ sau từ thứ ba.

Kết quả của trò chơi được đánh giá bởi trí tuệ nhân tạo. Để tránh tranh cãi, người chơi đồng ý không tranh cãi về tính hợp lệ của chuỗi được đánh giá bởi AI trong một trò chơi cụ thể, ngay cả khi đánh giá là gây tranh cãi hoặc sai lầm, thay đổi theo thời gian hoặc khác nhau giữa các người chơi khác nhau.

Thưởng thức trò chơi!`;