import React, { useState } from "react";
import HTMLtoReact from 'html-to-react';
import { useCat } from "../contexts/CatContext";
import Neono from "./Neono";
import './MyChains.scss';

export default function MyChains() {

  const { loading, myChains } = useCat();
  const [showId, setShowId] = useState(false);

  const onChainShow = (id) => {
    setShowId(id);
  }

  const Explanation = ({ html }) => {
    const htmlToReactParser = new HTMLtoReact.Parser();
    const jsx = htmlToReactParser.parse(html);

    return <>{jsx}</>;
  };


  return (
    <div className={`MyChains`}>
      <div className={`MyChains__inner ${showId ? 'fullwidth' : ''}`}>
        {loading && <Neono />}
        {!showId ? (

          <div className={`chainset`}>
            {myChains?.length > 0 ? myChains.map((ch, i) => (
              <div className="chain__wrapper" id={ch.id} key={i} onClick={() => onChainShow(ch.id)}>
                <div className="chain__header">
                  {i + 1}
                </div>
                <div className="chain" id={ch.id}>
                  {ch.chain.replace(/\s—\s/g, ' \u2022 ')}
                </div>
              </div>
            )) : (
              <div className="no-chains">
                <p>You have no chains yet. <a href="/">Play</a> to get some.   </p>
              </div>
            )}
          </div>
        ) : (
          myChains?.length && myChains.map((ch, i) => (
            ch.id === showId && (
              <div className="modal" key={i}>
                <span className="material-symbols-outlined icon-button modal-close" onClick={() => setShowId(false)}>close</span> 

                <div className={`explanation`}>
                  <h2>{ch.chain.replace(/\s—\s/g, ' \u2022 ')}</h2>
                  <Explanation html={ch.explanation} />
                </div>
              </div>
            )
          ))
        )}
      </div>
    </div>
  )
}