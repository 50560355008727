export const use_en = `## Uses

* **Learning your native language:** the game helps to learn new words, to understand the meanings of words more accurately, to distinguish semantic nuances, to express your thoughts clearly.
* **Learning foreign languages:** building word chains and establishing connections between them helps to memorize words and expressions.
* **Development of thinking:**, imagination, attention, memory: building word chains involves both creative and critical thinking, teaches to create new and soberly evaluate it.
* **Idea generation:** the game can be used to generate ideas for creative projects, to find new solutions to problems, to develop new concepts.`;

export const use_ru = `## Применения

* **Изучение родного языка:** игра способствует узнаванию новых слов, более точному пониманию значений слов, учит различать смысловые нюансы, понятно выражать свои мысли.
* **Изучение иностранных языков:** построение цепочек слов и установление связей между ними способствует запоминанию слов и выражений.
* **Развитие мышления:**, воображения, внимания, памят:  построение цепочек слов задействует как креативное,так и критическое мышление, учить создвавать новое и трезво оценивать его
* **Генерация идей**: игру можно использовать для генерации идей для творческих проектов, поиска решений, разработки новых концепций.`;

export const use_cs = `## Použití

* **Učení se mateřskému jazyku:** hra pomáhá naučit se nová slova, lépe porozumět významům slov, rozlišovat sémantické nuance, jasně vyjadřovat své myšlenky.
* **Učení se cizím jazykům:** budování slovních řetězců a navazování mezi nimi pomáhá zapamatovat si slova a výrazy.
* **Rozvoj myšlení:**, představivosti, pozornosti, paměti: budování slovních řetězců zapojuje jak kreativní, tak kritické myšlení, učí tvořit nové a střízlivě je posuzovat.
* **Generování nápadů:** hru lze použít k generování nápadů na tvůrčí projekty, hledání řešení, vývoj nových konceptů.`;


export const use_vn = `## Ứng dụng

* **Học ngôn ngữ bản địa:** trò chơi giúp học từ mới, hiểu ý nghĩa của từ chính xác hơn, phân biệt các sắc thái ngữ nghĩa, diễn đạt suy nghĩ một cách rõ ràng.
* **Học ngoại ngữ:** xây dựng chuỗi từ và thiết lập các kết nối giữa chúng giúp ghi nhớ từ và cụm từ.
* **Phát triển tư duy:**, trí tưởng tượng, sự chú ý, trí nhớ: xây dựng chuỗi từ liên quan đến cả tư duy sáng tạo và phê phán, dạy cách tạo ra những thứ mới và đánh giá nó một cách tỉnh táo.
* **Tạo ra ý tưởng:** trò chơi có thể được sử dụng để tạo ra ý tưởng cho các dự án sáng tạo, tìm kiếm giải pháp, phát triển các khái niệm mới.`;