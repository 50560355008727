import React from "react";
import './Logo.scss';

export const Logo = ({ color = "white" }) => (

  <div className="Logo">
    <div className="logo__full">
      <h1>c · a · t · e · n · a</h1>
      {/*
      <svg width="100%" height="100%" id="uuid-c41e79fe-d5ad-434e-b11f-9313fa590fda" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 447.64 97.25">
        <path d="m36.3,15.5c3.7,0,7.12.43,10.28,1.28,3.15.85,5.87,2.03,8.17,3.53,2.4,1.5,4.3,3.38,5.7,5.62,1.4,2.25,2.1,4.72,2.1,7.42,0,3.3-1,5.95-3,7.95-2,2-4.5,3-7.5,3s-5.62-.9-7.57-2.7c-1.95-1.8-2.93-4.2-2.93-7.2s.85-5.3,2.55-7.2c1.7-1.9,3.75-3.15,6.15-3.75-.9-1.3-2.3-2.37-4.2-3.22-1.9-.85-4-1.28-6.3-1.28-3,0-5.7.78-8.1,2.33-2.4,1.55-4.48,3.85-6.23,6.9-1.75,3.05-3.1,6.8-4.05,11.25-.95,4.45-1.42,9.58-1.42,15.38,0,8.1.97,14.5,2.92,19.2,1.95,4.7,4.47,8.03,7.58,9.98,3.1,1.95,6.4,2.92,9.9,2.92,2.1,0,4.35-.4,6.75-1.2,2.4-.8,4.77-2.15,7.12-4.05,2.35-1.9,4.47-4.6,6.38-8.1l2.85,1.05c-1.1,3.5-2.88,7-5.33,10.5-2.45,3.5-5.62,6.4-9.52,8.7-3.9,2.3-8.65,3.45-14.25,3.45-6.4,0-12.2-1.48-17.4-4.42-5.2-2.95-9.33-7.42-12.38-13.42-3.05-6-4.58-13.45-4.58-22.35s1.55-16.28,4.65-22.43c3.1-6.15,7.43-10.88,12.98-14.17s11.77-4.95,18.67-4.95Z" fill={color} />
        <path d="m94.7,96.2c-4.3,0-7.95-.83-10.95-2.47-3-1.65-5.25-3.9-6.75-6.75-1.5-2.85-2.25-6.02-2.25-9.53,0-4.3,1-7.82,3-10.58,2-2.75,4.58-4.95,7.73-6.6s6.47-3.05,9.98-4.2c3.5-1.15,6.8-2.25,9.9-3.3,3.1-1.05,5.65-2.27,7.65-3.67,2-1.4,3-3.25,3-5.55v-10.95c0-3.2-.48-5.82-1.42-7.88-.95-2.05-2.35-3.6-4.2-4.65-1.85-1.05-4.18-1.58-6.97-1.58-2.1,0-4.3.3-6.6.9-2.3.6-4.2,1.75-5.7,3.45,2.6.7,4.62,2,6.08,3.9,1.45,1.9,2.17,4.2,2.17,6.9,0,3.1-1.03,5.53-3.07,7.28-2.05,1.75-4.63,2.62-7.73,2.62-3.3,0-5.8-1.05-7.5-3.15-1.7-2.1-2.55-4.55-2.55-7.35s.7-5.12,2.1-6.98c1.4-1.85,3.35-3.52,5.85-5.02,2.5-1.7,5.7-3.05,9.6-4.05,3.9-1,8.15-1.5,12.75-1.5s8.3.48,11.7,1.43c3.4.95,6.3,2.62,8.7,5.02,2.4,2.3,3.95,5.15,4.65,8.55.7,3.4,1.05,7.55,1.05,12.45v41.1c0,2.5.3,4.27.9,5.32.6,1.05,1.55,1.58,2.85,1.58,1,0,1.95-.25,2.85-.75.9-.5,1.9-1.2,3-2.1l1.65,2.55c-2.2,1.7-4.5,3.05-6.9,4.05-2.4,1-5.3,1.5-8.7,1.5s-6.28-.5-8.33-1.5c-2.05-1-3.55-2.45-4.5-4.35-.95-1.9-1.48-4.15-1.57-6.75-2.4,4-5.35,7.1-8.85,9.3-3.5,2.2-7.7,3.3-12.6,3.3Zm9-8.4c2.4,0,4.62-.65,6.67-1.95,2.05-1.3,3.92-3.25,5.62-5.85v-30.3c-1,1.5-2.43,2.85-4.27,4.05-1.85,1.2-3.82,2.4-5.92,3.6s-4.08,2.58-5.93,4.12c-1.85,1.55-3.4,3.45-4.65,5.7-1.25,2.25-1.88,5.12-1.88,8.62,0,3.9.95,6.88,2.85,8.92,1.9,2.05,4.4,3.08,7.5,3.08Z" fill={color} />
        <path d="m179.85,0v17.75h29.2v5.5h-29.2v57.2c0,3.6.65,6.15,1.95,7.65,1.3,1.5,3.15,2.25,5.55,2.25s4.4-.87,6.3-2.62c1.9-1.75,3.6-4.67,5.1-8.78l2.7,1.2c-1.5,4.9-3.83,8.98-6.98,12.22-3.15,3.25-7.58,4.88-13.27,4.88-3.4,0-6.28-.43-8.62-1.27-2.35-.85-4.38-2.07-6.08-3.67-2.2-2.3-3.67-5.05-4.42-8.25-.75-3.2-1.12-7.45-1.12-12.75V25.7c0-.42-.45-2.45-2.45-2.45h-5.75v-5.5h8.2V3.9c3.6-.1,6.95-.45,10.05-1.05,3.1-.6,6.05-1.55,8.85-2.85Z" fill={color} />
        <path d="m247.3,15.5c8.8,0,15.7,2.65,20.7,7.95,5,5.3,7.5,16.25,7.5,27.85h-50.55l-.15-5.35h33.3c.2-4.9-.1-9.42-.9-13.58-.8-4.15-2.08-7.47-3.83-9.97-1.75-2.5-4.03-3.75-6.82-3.75-3.8,0-7.1,2.23-9.9,6.67-2.8,4.45-4.55,11.73-5.25,21.83l.6.9c-.2,1.3-.33,2.7-.38,4.2-.05,1.5-.07,3-.07,4.5,0,6.9,1,12.6,3,17.1,2,4.5,4.6,7.8,7.8,9.9,3.2,2.1,6.5,3.15,9.9,3.15,2.3,0,4.65-.35,7.05-1.05,2.4-.7,4.75-2,7.05-3.9,2.3-1.9,4.4-4.6,6.3-8.1l2.85,1.2c-1.1,3.5-2.92,6.97-5.48,10.42-2.55,3.45-5.83,6.28-9.82,8.47-4,2.2-8.7,3.3-14.1,3.3-6.8,0-12.8-1.5-18-4.5-5.2-3-9.25-7.47-12.15-13.42-2.9-5.95-4.35-13.22-4.35-21.83,0-9.1,1.52-16.75,4.58-22.95,3.05-6.2,7.27-10.92,12.67-14.18,5.4-3.25,11.55-4.88,18.45-4.88Z" fill={color} />
        <path d="m336.99,15.5c4.2,0,7.58.55,10.12,1.65,2.55,1.1,4.62,2.5,6.22,4.2,1.8,1.9,3.08,4.35,3.83,7.35s1.12,7.05,1.12,12.15v40.35c0,4.2.77,7.05,2.33,8.55,1.55,1.5,4.17,2.25,7.88,2.25v3.15c-1.8-.1-4.58-.22-8.33-.38s-7.38-.22-10.88-.22c-3.7,0-7.3.07-10.8.22-3.5.15-6.1.28-7.8.38v-3.15c3.2,0,5.45-.75,6.75-2.25,1.3-1.5,1.95-4.35,1.95-8.55v-45.45c0-2.7-.25-5.12-.75-7.27-.5-2.15-1.5-3.85-3-5.1-1.5-1.25-3.65-1.88-6.45-1.88-3.3,0-6.23.9-8.78,2.7-2.55,1.8-4.55,4.28-6,7.42-1.45,3.15-2.17,6.68-2.17,10.58v39c0,4.2.65,7.05,1.95,8.55,1.3,1.5,3.55,2.25,6.75,2.25v3.15c-1.7-.1-4.25-.22-7.65-.38-3.4-.15-6.85-.22-10.35-.22-3.7,0-7.45.07-11.25.22-3.8.15-6.65.28-8.55.38v-3.15c3.7,0,6.32-.75,7.88-2.25,1.55-1.5,2.33-4.35,2.33-8.55v-47.25c0-4.5-.73-7.82-2.18-9.97-1.45-2.15-4.12-3.23-8.02-3.23v-3.15c3.2.3,6.3.45,9.3.45,3.7,0,7.22-.17,10.57-.53,3.35-.35,6.42-.87,9.23-1.58v15c2.5-5.7,5.95-9.7,10.35-12,4.4-2.3,9.2-3.45,14.4-3.45Z" fill={color} />
        <path d="m396.19,96.2c-4.3,0-7.95-.83-10.95-2.47-3-1.65-5.25-3.9-6.75-6.75-1.5-2.85-2.25-6.02-2.25-9.53,0-4.3,1-7.82,3-10.58,2-2.75,4.58-4.95,7.73-6.6,3.15-1.65,6.47-3.05,9.97-4.2,3.5-1.15,6.8-2.25,9.9-3.3,3.1-1.05,5.65-2.27,7.65-3.67,2-1.4,3-3.25,3-5.55v-10.95c0-3.2-.48-5.82-1.42-7.88-.95-2.05-2.35-3.6-4.2-4.65-1.85-1.05-4.18-1.58-6.97-1.58-2.1,0-4.3.3-6.6.9-2.3.6-4.2,1.75-5.7,3.45,2.6.7,4.62,2,6.07,3.9,1.45,1.9,2.18,4.2,2.18,6.9,0,3.1-1.03,5.53-3.08,7.28-2.05,1.75-4.63,2.62-7.73,2.62-3.3,0-5.8-1.05-7.5-3.15-1.7-2.1-2.55-4.55-2.55-7.35s.7-5.12,2.1-6.98c1.4-1.85,3.35-3.52,5.85-5.02,2.5-1.7,5.7-3.05,9.6-4.05,3.9-1,8.15-1.5,12.75-1.5s8.3.48,11.7,1.43c3.4.95,6.3,2.62,8.7,5.02,2.4,2.3,3.95,5.15,4.65,8.55.7,3.4,1.05,7.55,1.05,12.45v41.1c0,2.5.3,4.27.9,5.32.6,1.05,1.55,1.58,2.85,1.58,1,0,1.95-.25,2.85-.75.9-.5,1.9-1.2,3-2.1l1.65,2.55c-2.2,1.7-4.5,3.05-6.9,4.05-2.4,1-5.3,1.5-8.7,1.5s-6.28-.5-8.32-1.5c-2.05-1-3.55-2.45-4.5-4.35-.95-1.9-1.48-4.15-1.58-6.75-2.4,4-5.35,7.1-8.85,9.3-3.5,2.2-7.7,3.3-12.6,3.3Zm9-8.4c2.4,0,4.62-.65,6.67-1.95,2.05-1.3,3.92-3.25,5.62-5.85v-30.3c-1,1.5-2.43,2.85-4.27,4.05-1.85,1.2-3.83,2.4-5.93,3.6-2.1,1.2-4.08,2.58-5.92,4.12-1.85,1.55-3.4,3.45-4.65,5.7-1.25,2.25-1.88,5.12-1.88,8.62,0,3.9.95,6.88,2.85,8.92,1.9,2.05,4.4,3.08,7.5,3.08Z" fill={color} />
      </svg>
    */}
    </div>

    <div className="logo__short">
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.88 85.85">
        <path d="m38.12,0c3.88,0,7.48.45,10.79,1.34,3.31.89,6.17,2.13,8.59,3.7,2.52,1.58,4.51,3.54,5.99,5.91,1.47,2.36,2.21,4.96,2.21,7.8,0,3.47-1.05,6.25-3.15,8.35-2.1,2.1-4.73,3.15-7.88,3.15s-5.91-.95-7.96-2.83c-2.05-1.89-3.07-4.41-3.07-7.56s.89-5.56,2.68-7.56c1.78-1.99,3.94-3.31,6.46-3.94-.95-1.36-2.42-2.49-4.41-3.39-2-.89-4.2-1.34-6.62-1.34-3.15,0-5.99.81-8.51,2.44-2.52,1.63-4.7,4.04-6.54,7.25-1.84,3.2-3.26,7.14-4.25,11.81-1,4.67-1.5,10.06-1.5,16.15,0,8.51,1.02,15.23,3.07,20.16,2.05,4.94,4.7,8.43,7.96,10.48,3.25,2.05,6.72,3.07,10.4,3.07,2.21,0,4.57-.42,7.09-1.26,2.52-.84,5.01-2.26,7.48-4.25,2.47-1.99,4.7-4.83,6.7-8.51l2.99,1.1c-1.16,3.68-3.02,7.35-5.59,11.03-2.57,3.68-5.91,6.72-10,9.14-4.1,2.42-9.08,3.62-14.96,3.62-6.72,0-12.81-1.55-18.27-4.65-5.46-3.1-9.79-7.8-13-14.1C1.6,60.81,0,52.98,0,43.63s1.63-17.09,4.88-23.55c3.25-6.46,7.8-11.42,13.63-14.89S30.88,0,38.12,0Z" fill={color} />
        <path d="m129.85,84.75c-4.52,0-8.35-.87-11.5-2.6-3.15-1.73-5.51-4.1-7.09-7.09s-2.36-6.33-2.36-10c0-4.51,1.05-8.22,3.15-11.11,2.1-2.89,4.8-5.2,8.11-6.93s6.8-3.2,10.48-4.41c3.68-1.21,7.14-2.36,10.4-3.47,3.25-1.1,5.93-2.39,8.04-3.86,2.1-1.47,3.15-3.41,3.15-5.83v-11.5c0-3.36-.5-6.12-1.5-8.27-1-2.15-2.47-3.78-4.41-4.88-1.94-1.1-4.39-1.65-7.32-1.65-2.21,0-4.52.31-6.93.95-2.42.63-4.41,1.84-5.99,3.62,2.73.74,4.86,2.1,6.38,4.1,1.52,2,2.28,4.41,2.28,7.25,0,3.26-1.08,5.8-3.23,7.64-2.15,1.84-4.86,2.76-8.11,2.76-3.47,0-6.09-1.1-7.88-3.31-1.79-2.21-2.68-4.78-2.68-7.72s.73-5.38,2.21-7.33c1.47-1.94,3.52-3.7,6.14-5.28,2.62-1.78,5.99-3.2,10.08-4.25,4.1-1.05,8.56-1.58,13.39-1.58s8.72.5,12.29,1.5c3.57,1,6.62,2.76,9.14,5.28,2.52,2.42,4.15,5.41,4.88,8.98.73,3.57,1.1,7.93,1.1,13.08v43.16c0,2.63.32,4.49.95,5.59.63,1.1,1.63,1.65,2.99,1.65,1.05,0,2.05-.26,2.99-.79s1.99-1.26,3.15-2.21l1.73,2.68c-2.31,1.79-4.72,3.2-7.25,4.25-2.52,1.05-5.57,1.58-9.14,1.58s-6.59-.53-8.74-1.58c-2.15-1.05-3.73-2.57-4.73-4.57-1-1.99-1.55-4.36-1.65-7.09-2.52,4.2-5.62,7.46-9.29,9.77-3.68,2.31-8.09,3.47-13.23,3.47Zm9.45-8.82c2.52,0,4.86-.68,7.01-2.05,2.15-1.36,4.12-3.41,5.91-6.14v-31.82c-1.05,1.58-2.55,2.99-4.49,4.25-1.94,1.26-4.02,2.52-6.22,3.78-2.21,1.26-4.28,2.71-6.22,4.33-1.94,1.63-3.57,3.62-4.88,5.99-1.31,2.36-1.97,5.38-1.97,9.06,0,4.1,1,7.22,2.99,9.37,1.99,2.15,4.62,3.23,7.88,3.23Z" fill={color} />
        <path d="m93.06,42.91c0,3.44-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22,2.79-6.22,6.22-6.22,6.22,2.79,6.22,6.22Z" fill={color} />
      </svg>
    </div>

  </div>

);
