import React from "react";
import ReactMarkdown from 'react-markdown';
import Layout from '../Layout';
import { useCat } from "../contexts/CatContext";
import Banner from "./Banner";
import { rulesVer } from "../lib/constants";
import './Static.scss';

export default function Rules() {

  const {rules} = useCat();

return (
  <Layout>
    <Banner />
    <div className="static">
      <ReactMarkdown>{rules}</ReactMarkdown>
      <span>{rulesVer}</span>
    </div>
  </Layout>
  )
}
