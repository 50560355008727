import React, { useState, useEffect } from 'react';
import { useCat } from "../contexts/CatContext";
import teens from '../assets/images/1---image-1200x540.png';
import youngsters from '../assets/images/2---image-1200x540.png';
import seniors from '../assets/images/3---image-1200x540.png';

const images = [teens, youngsters, seniors]; // Your image URLs

export default function Slider () {
  const { step } = useCat();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);


  return (
    !step &&
    <div className="terzia">

      <div className="box">
        <div className="slider">
          {images.map((img, index) =>
            <div
              key={index}
              className={`slide slide-${index} ${index === activeIndex ? ' active appear' : ' hidden'}`}
            >
              <img
                alt=""
                src={img}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
