import React, {
  createContext, useContext, useEffect, useState,
} from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import store from "store2";
import { toast } from "react-toastify";
import { oaiChatEndpoint, tokenEndpoint, userEndpoint, profilesEndpoint, chainsEndpoint, addChainsEndpoint, reqOptionsGet } from "../lib/constants";
import { pairs_en, pairs_ru, pairs_cs, pairs_vn } from '../lib/pairs';
import { rules_en, rules_ru, rules_cs, rules_vn } from '../lib/page_rules';
import { use_en, use_ru, use_cs, use_vn } from '../lib/page_uses';
import { ai_talk_en } from '../lib/ai_talk';
import { catena_front_en, catena_front_ru, catena_front_cs, catena_front_vn } from '../lib/catena_front';
import { front } from '../lib/translate';

const countScore = (chain) => {
  if (chain?.length > 0) {

    switch (chain.length) {
      case 3:
        return 10;
      case 4:
        return 8;
      case 5:
        return 6;
      case 6:
        return 4;
      case 7:
        return 2;

      default:
        return 0;
    }
  }
}

const CatContext = createContext({
  loading: false,
  setLoading: () => { },
  userId: '',
  setUserId: () => { },

  myProfile: {},
  setMyProfile: () => { },
  updateMyProfile: () => { },
  myChains: [],

  handleWpLogin: () => { },
  handleWpRegister: () => { },
  handleWpLogout: () => { },

  gameType: {},
  setGameType: () => { },
  getRandomPair: () => { },
  initPair: [],
  setInitPair: () => { },
  startNewGame: () => { },
  chain: [],
  setChain: () => { },
  chainset: [],
  setChainset: () => { },
  processChainset: () => { },
  reset: () => { },
  step: 0,
  setStep: () => { },
  allResults: [],

  lang: '',
  handleLang: () => { },
  rules: '',
  use: '',
  catena_front: '',
  ai_talk_: '',
  translate: () => { },
  wordPairs: [],
  f: () => { },
});

export default function CatProvider({ children }) {

  const navigate = useNavigate();

  // Custom hook for managing state synchronization with local storage

  function useLocalStorageState(key, defaultValue) {
    const [value, setValue] = useState(() => {
      const storedValue = store.get(key);
      return typeof storedValue !== undefined ? storedValue : defaultValue;
    });

    useEffect(() => {
      store.set(key, value);
    }, [key, value]);
    return [value, setValue];
  }

  const initLang = () => {
    const defaultLang = 'en';
    const acceptedLangs = ['en', 'ru', 'cs', 'vn'];
    if (store.has('lang')) {
      const storedLang = store.get('lang');
      if (acceptedLangs.includes(storedLang))
        return storedLang;
    }
    const browserLang = navigator.language.slice(0, 2);
    if (acceptedLangs.includes(browserLang)) {
      store.set('lang', browserLang);
      return browserLang;
    }
    return defaultLang;
  }

  const [userId, setUserId] = useLocalStorageState('userId', '');
  const [myChains, setMyChains] = useLocalStorageState('myChains', []);
  const [lang, setLang] = useLocalStorageState('lang', initLang())
  const [myProfile, setMyProfile] = useLocalStorageState('myProfile', {
    id: '',
    nick: '',
    av_url: '',
    av_id: null,
    total_games: 0,
    total_chains: 0,
    total_score: 0,
    user_id: '',
  });
  const [gameType, setGameType] = useLocalStorageState('gameType', 'solo-1');
  const [initPair, setInitPair] = useLocalStorageState('initPair', []);
  const [chain, setChain] = useLocalStorageState('chain', []);
  const [chainset, setChainset] = useLocalStorageState('chainset', []);
  const [step, setStep] = useLocalStorageState('step', 0);
  const [allResults, setAllResults] = useLocalStorageState('allResults', []);

  const [rules, setRules] = useState('');
  const [use, setUse] = useState('');
  const [catena_front, setCatenaFront] = useState('');
  const [wordPairs, setWordPairs] = useState();
  const [ai_talk, setAiTalk] = useState();

  const [loading, setLoading] = useState(false);



  useEffect(() => {
    setAiTalk(ai_talk_en);
    switch (lang) {
      case 'ru':
        setRules(rules_ru);
        setCatenaFront(catena_front_ru);
        setWordPairs(pairs_ru);
        setUse(use_ru);
        break;
      case 'cs':
        setRules(rules_cs);
        setCatenaFront(catena_front_cs);
        setWordPairs(pairs_cs);
        setUse(use_cs);
        break;
      case 'vn':
        setRules(rules_vn);
        setCatenaFront(catena_front_vn);
        setWordPairs(pairs_vn);
        setUse(use_vn);
        break;
      case 'en':
      default:
        setRules(rules_en);
        setCatenaFront(catena_front_en);
        setWordPairs(pairs_en);
        setUse(use_en);
        break;
    }
  }, [lang]);

  const handleLang = (l) => {
    if (l === lang)
      return;
    setLang(l);
  }

  function translate(txt, matrix) {
    if (lang === 'en')
      return txt;
    const foundKey = matrix.en.find(obj => Object.values(obj).includes(txt));
    if (!foundKey)
      return txt;
    const key = foundKey && Object.keys(foundKey)[0];
    const tarObj = key && matrix[lang].find(obj => obj[key]);
    return tarObj ? tarObj[key] : txt;
  };

  const f = (text) => translate(text, front);

  const reset = () => {
    setStep(0);
    setGameType('solo-1');
    setChain([]);
    setChainset([]);
    setInitPair([]);
    setMyChains([]);
    setAllResults([]);
    store.set('initPair', []);
    store.set('chainset', []);
    store.set('chain', []);
    store.set('myChains', []);
  };

  const getRandomPair = () => wordPairs[Math.floor(Math.random() * wordPairs.length)];

  const startNewGame = async (gType) => {
    if (!userId) {
      toast.warning('Please login to play');
    }
    const defPair = getRandomPair();
    reset();
    setGameType(gType);
    setStep(1);
    setInitPair(defPair);
    setChain(defPair);
    setChainset([]);
    store.set('initPair', defPair);
    store.set('chain', defPair);
    store.set('chainset', []);
  };

  const handleWpLogout = () => {
    reset();
    setUserId(null);
    setMyProfile(null);
    setMyChains([]);
    store(false);
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function checkPasswordStrength(password) {
    const lengthRegex = /.{8,}/;  // Minimum length of 8 characters
    const uppercaseRegex = /[A-Z]/;  // At least one uppercase letter
    const lowercaseRegex = /[a-z]/;  // At least one lowercase letter
    const digitRegex = /\d/;  // At least one digit
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;  // At least one special character

    let strength = 0;
    strength += lengthRegex.test(password) ? 1 : 0;
    strength += uppercaseRegex.test(password) ? 1 : 0;
    strength += lowercaseRegex.test(password) ? 1 : 0;
    strength += digitRegex.test(password) ? 1 : 0;
    strength += specialCharRegex.test(password) ? 1 : 0;

    if (strength === 5) {
      return 'Very Strong';
    } else if (strength >= 3) {
      return 'Strong';
    } else if (strength >= 2) {
      return 'Moderate';
    } else if (strength >= 1) {
      return false; // 'Weak';
    } else {
      return false; //'Very Weak';
    }
  }

  const checkUserCredentials = async (username, password, email = '', ops) => {
    let p = DOMPurify.sanitize(password.trim());
    let u = DOMPurify.sanitize(username.trim());
    let e = email !== null ? DOMPurify.sanitize(email.trim()) : '';
    if (email) {
      e = DOMPurify.sanitize(email.trim());
    }

    if (p === "") {
      toast.warning("Please enter your password");
      return;
    }
    if (u === "") {
      toast.warning("Please enter your username");
      return;
    }
    if (ops === 'register' && e === "") {
      toast.warning("Please enter your email address");
      return;
    } else if (ops === 'register' && !isValidEmail(e)) {
      toast.warning("Please enter a valid email address");
      return;
    }
    if (ops === 'register' && checkPasswordStrength(p) === false) {
      toast.warning("Please enter a stronger password");
      return;
    }
    return { username: u, password: p, email: e ?? '' };

  }


  const handleWpLogin = async (username, password) => {

    const checked = await checkUserCredentials(username, password);

    if (checked === false) {
      return false;
    }

    try {
      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: checked.username, password: checked.password }), // Use email instead of username
      });

      if (!response.ok) {
        toast('Invalid credentials');
        return false;
      }

      const data = await response.json();
      const { token, id } = data.data;

      !userId && setUserId(`${id}`);
      store.set('token', token);
      toast.success("Login successful");
      return true;

    } catch (error) {
      toast.error("Login error");
      console.log("Login error: ", error);
      return false;
    }

  };


  const handleWpRegister = async (username, password, email) => {

    const checked = await checkUserCredentials(username, password, email);

    if (checked === false) {
      return false;
    }

    try {
      const response = await fetch(userEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa('app-rest-user:' + process.env.REACT_APP_WP_PASS)}`,
        },
        body: JSON.stringify({
          username: checked.username,
          password: checked.password,
          email: checked.email,
        })
      });

      const result = await response.json();
      console.log('registered user: ', result);

      if (result && response.ok) {
        setUserId(result.id);
        const profile = await createUserPost(result.id);
        // const profileUpdated = updateMyProfile({ id: result.id });

        if (profile) {
          console.log('post created', profile);
          toast.success('Registration successful, logging you in...');
          await handleWpLogin(username, password);
          navigate('/dashboard');
        }
      } else if (result.code === 'existing_user_login') {
        toast.warning('A user with this email already exists. Please login or use a different email address.');
        return false;
      }
    } catch (error) {
      console.error("Registration error: ", error);
    }
  };

  // create user post

  const createUserPost = async (userId) => {
    console.log('creating post for userId: ', userId);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa('app-rest-user:' + process.env.REACT_APP_WP_PASS)}`,
      },
      body: JSON.stringify({
        title: `${userId}`,
        content: '',
        status: 'publish',
        acf: {
          id: null,
          nick: 'New Player',
          av_id: null,
          av_url: '',
          total_score: 0,
          total_chains: 0,
          total_games: 0,
          user_id: `${userId}`,
        },
      }),
    };

    try {
      const response = await fetch(profilesEndpoint, requestOptions);
      const data = await response.json();
      console.log('post created', data);
      return data;
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  // returns a url for fetching an array of all items with an acf key having a specific value
  const makeAcfRequestUrl = (apiUrl, k, v, n = 100) => {
    if (!apiUrl || !k || !v) return;
    const url = `${apiUrl}?acf[${k}]=${v}&meta_query[relation]=AND&meta_query[0][key]=${k}&meta_query[0][value]=${v}&meta_query[0][compare]=LIKE&per_page=${n}`;
    return url;
  }

  const getMyChains = async (userId) => {
    let allData = [];
    let page = 1;
    let numPages = 1;
    let total = 0;

    try {

      do {
        const url = chainsEndpoint + '?per_page=100&acf&page=' + `${page}`;
        const response = await fetch(url, reqOptionsGet);

        total = response.headers.get('x-wp-total') ?? 0;
        numPages = response.headers.get('x-wp-totalpages') ?? 0;
        const data = await response.json();
        if (data?.length > 0 ) {
          allData = [...allData, ...data];
        }
        page++;

      } while (allData?.length < total);
      
      if (allData?.length === 0) 
        return;
    
      console.log('allData', allData);

      const userChains = allData.filter(d => `${d.acf?.user_id}` === `${userId}`);

      console.log('userChains', userChains);

      const chains = userChains.map(ch => ({
        id: `${ch.id}`,
        chain: ch.title?.rendered,
        user_id: `${ch.acf?.user_id}`,
        explanation: ch.content?.rendered,
      }));

      setMyChains([...chains]);

    } catch (error) {
      console.log(`Error fetching chains: ${error}`);
    }
  }

  const getMyProfile = async (userId) => {
    const url = makeAcfRequestUrl(profilesEndpoint, 'user_id', userId);

    try {
      const response = await fetch(url, reqOptionsGet);
      const data = await response.json();

      let id = null;
      let profile = null;

      for (let i = 0; i < data.length; i++) {
        if (data[i].acf?.user_id == userId) {
          id = data[i].id;
        }
        if (id) {
          profile = data[i].acf;
          console.log('dti', data[i]);
          console.log(`Profile found for user ${userId}: ${profile}`);
          break;
        };
      };
      if (!profile) {
        console.log(`No profile found for user ${userId}`);
        return false;
      }

      await setMyProfile({ ...myProfile, id, ...profile });
      console.log('myProfile', myProfile);
      store.set('myProfile', { ...myProfile, id, ...profile });
      return true;

    } catch (error) {
      console.log(`Error fetching user profile: ${error}`);
      return false;
    }
  }

  const updateDb = async (kvs) => { // kvs = array of key-value pairs
    const url = profilesEndpoint + '/' + myProfile.id;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa('app-rest-user:' + process.env.REACT_APP_WP_PASS)}`,
      },
      url,
      body: JSON.stringify({
        acf: {
          ...kvs,
        },
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const r = await response.json();
      // console.log('user updated', r);
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      return false;
    }
  }

  const updateMyProfile = async (kvs) => {
    const r = await updateDb(kvs);
    if (!r) {
      console.error('updateDb failed');
      return false;
    }
    const updated = { ...myProfile, ...kvs };
    store.set('myProfile', updated);
    setMyProfile({ ...updated });
    return r;
  }

  // main game logic: process chainset, calc scores, save valid chains & scores
  const processChainset = async () => {
    let validated = [];

    setLoading(true);

    for (let c of chainset) {
      const checked = await checkChain(c);
      console.log('checked', checked);

      validated.push({
        chainAsArray: c,
        chainAsString: c.join(' — '),
        score: checked.isValid ? countScore(c) : 0,
        explanation: checked.reply,
        lang,
        user_id: userId,
      });
    }


    if (typeof validated === 'undefined' || !Array.isArray(validated) || validated?.length === 0) {
      toast.error('No chains submitted. Try again! (1)');

      setLoading(false);
      setStep(4);
      return false;
    }

    setAllResults(validated);

    const validChains = validated.filter(v => v.score > 0) ?? [];

    if (typeof validChains === 'undefined' || validChains.length === 0 || !Array.isArray(validChains)) {
      toast.error('No valid chains submitted. Try again! (2)');
      setLoading(false);
      setStep(4);
      return false;
    }

    if (validChains.length > 0 && validChains.length < chainset.length) {
      toast.warning('Some chains were invalid and didn\'t count towards your score');
    } else if (validChains.length === chainset.length) {
      validChains.length === 1
        ? toast.success('The chain is valid. Well done!')
        : toast.success('All chains were valid. Nicely done!');
    }

    const r = await saveValid(validChains);
    setLoading(false);

    if (!r) {
      console.error('Saving chains failed');
      return false;
    }
    setStep(4);
    return true;
  }

  const printPairs = (items) => { // returns a string of pairs item, next item; item, next item; etc.
    return items.map((v, i) => {
      if (i < items.length - 1) return `${v} — ${items[i + 1]}`; // omega      
    }).join('; ');
  }

  const checkChain = async (ch) => {

    const instructions = ai_talk + printPairs(ch);

    // console.log('instructions', instructions);

    try {

      const body = {
        model: 'gpt-3.5-turbo',
        messages: [
          { "role": "system", "content": rules },
          { "role": 'user', "content": instructions }
        ],
        max_tokens: 3000,
        user: 'user_' + userId,
      };


      const response = await fetch(oaiChatEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      // console.log('data', data);
      const reply = data.choices[0].message.content;

      return {
        isValid: reply.match(/The chain is valid/) ? true : false,
        reply,
      };


    } catch (error) {
      // console.log(error);
      return false;
    }
  }


  const saveValid = async (validChains) => {

    const reqArray = [];

    for (let c of validChains) {
      reqArray.push({
        post: {
          post_type: 'chain',
          post_title: c.chainAsString,
          post_content: c.explanation,
          post_status: 'Publish',
        },
        acf: {
          lang: c.lang,
          user_id: c.user_id,
          score: c.score,
        },
      });
    }

    const response = await fetch(addChainsEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${store.get('token')}`,
      },
      body: JSON.stringify({
        chainset: reqArray,
      }),
    });

    console.log('Response:', response);
    const data = await response.json();
    data && console.log('Data:', data);

    if (!response.ok || !data || !Array.isArray(data) || data.length !== validChains.length) {
      console.log('saving chains failed');
      return false;
    }
    
    // Update user profile if the chains were saved successfully
    const kvs = {
      total_games: parseInt(myProfile.total_games) + 1,
      total_score: parseInt(myProfile.total_score) + validChains.reduce((a, b) => a + b.score, 0),
      total_chains: parseInt(myProfile.total_chains) + validChains.length,
    };
    const r = await updateMyProfile(kvs);

    if (!r) {
      console.error('updateMyProfile failed');
      return false;
    }


    return true;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (userId && !myProfile?.id) {
        await getMyProfile(userId);
      }
      if (myProfile && myProfile.total_chains > 0) {
        await getMyChains(userId);
      }
    };
  
    fetchData();
  }, [userId, myProfile?.total_chains]);
  

  const values = {
    userId,
    setUserId,
    myProfile,
    setMyProfile,
    updateMyProfile,
    handleWpLogin,
    handleWpRegister,
    handleWpLogout,

    gameType,
    setGameType,
    loading,
    setLoading,
    getRandomPair,
    initPair,
    setInitPair,
    startNewGame,
    chain,
    setChain,
    chainset,
    setChainset,
    processChainset,
    myChains,
    reset,
    step,
    setStep,
    allResults,

    lang,
    handleLang,
    rules,
    use,
    catena_front,
    ai_talk,
    translate,
    wordPairs,
    f,
  };

  return (
    <CatContext.Provider value={values}>
      {children}
    </CatContext.Provider>
  );

}

export const useCat = () => useContext(CatContext);

