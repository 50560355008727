
export const gameLib = {

  'solo-1': {
    name: 'solo-1',
    title: 'Solo 1',
    chainsetLen: 1,
    samePair: true,
    goal: {
      en: "Make one chain from the given word pair",
      ru: "Составьте одну цепочку из заданной пары слов",
      cs: "Složte jeden řetězec z dané dvojice slov",
      vn: "Tạo một chuỗi từ cặp từ đã cho",
    },
  },
  
  'solo-3': {
    name: 'solo-3',
    title: 'Solo 3',
    chainsetLen: 3,
    samePair: false,
    goal: {
      en: "Make 3 chains using 3 given word pairs",
      ru: "Составьте 3 цепочки из 3 заданных пар слов",
      cs: "Složte 3 řetězce z 3 daných dvojic slov",
      vn: "Tạo 3 chuỗi từ 3 cặp từ đã cho",
    },
  },

  'solo-5': {
    name: 'solo-5',
    title: 'Solo 5',
    chainsetLen: 5,
    samePair: false,
    goal: {
      en: "Make 5 chains using 5 given word pairs",
      ru: "Составьте 5 цепочек из 5 заданных пар слов",
      cs: "Složte 5 řetězců z 5 daných dvojic slov",
      vn: "Tạo 5 chuỗi từ 5 cặp từ đã cho",
    },
  },

  'solo-7': {
    name: 'solo-7',
    title: 'Solo 7',
    chainsetLen: 7,
    samePair: false,
    goal: {
      en: "Make 7 chains using 7 given word pairs",
      ru: "Составьте 7 цепочек из 7 заданных пар слов",
      cs: "Složte 7 řetězců z 7 daných dvojic slov",
      vn: "Tạo 7 chuỗi từ 7 cặp từ đã cho",
    },
  },

  'solo-2-s': {
    name: 'solo-2-s',
    title: 'Solo 2 Same',
    chainsetLen: 2,
    samePair: true,
    goal: {
      en: "Make 2 chains using the same word pair",
      ru: "Составьте 2 цепочки из одной заданной пары слов",
      cs: "Složte 2 řetězce z jedné dané dvojice slov",
      vn: "Tạo 2 chuỗi từ cùng một cặp từ",
    },
  },
  'solo-3-s': {
    name: 'solo-3-s',
    title: 'Solo 3 Same',
    chainsetLen: 3,
    samePair: true,
    goal: {
      en: "Make 3 chains using the same word pair",
      ru: "Составьте 3 цепочки из одной заданной пары слов",
      cs: "Složte 3 řetězce z jedné dané dvojice slov",
      vn: "Tạo 3 chuỗi từ cùng một cặp từ",
    },
  },

  'solo-5-s': {
    name: 'solo-5-s',
    title: 'Solo 5 Same',
    chainsetLen: 5,
    samePair: true,
    goal: {
      en: "Make 5 chains using the same word pair",
      ru: "Составьте 5 цепочек из одной заданной пары слов",
      cs: "Složte 5 řetězců z jedné dané dvojice slov",
      vn: "Tạo 5 chuỗi từ cùng một cặp từ",
    },
  },

  'solo-7-s': {
    name: 'solo-7-s',
    title: 'Solo 7 Same',
    chainsetLen: 7,
    samePair: true,
    goal: {
      en: "Make 7 chains using the same word pair",
      ru: "Составьте 7 цепочек из одной заданной пары слов",
      cs: "Složte 7 řetězců z jedné dané dvojice slov",
      vn: "Tạo 7 chuỗi từ cùng một cặp từ",
    },
  },
};



