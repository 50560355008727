import React, { useState } from 'react';
import { useCat } from "../contexts/CatContext";
import { toast } from 'react-toastify';
import { score_words } from '../lib/translate';


export default function Meta () {
  const { lang, userId, myProfile, updateMyProfile } = useCat();

  const [openEdit, setOpenEdit] = useState(false);
  const [nick, setNick] = useState(myProfile?.nick);

  const t = (key) => score_words[lang][key];

  const handleEdit = async () => {
    const r = updateMyProfile({ nick: nick });
    if (r === true)
      toast.success('User updated');
    setOpenEdit(!openEdit);
  }

  return (
    <div className="atts">

      <div className="user-profile att">
        {openEdit ? (
          <h2>
            <input
              className="nick"
              type="text"
              defaultValue={nick ?? myProfile?.nic ?? 'New player'}
              onChange={(e) => setNick(e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && handleEdit()}
            />
            <i className="material-symbols-outlined icon-button checkmark"
              role="button"
              onClick={() => handleEdit()}
            >
              check
            </i>
          </h2>
        ) : (
          <h2>
            {myProfile?.nick ? myProfile.nick : 'New player'}

            <i className="material-symbols-outlined icon-button edit"
              role="button"
              onClick={() => setOpenEdit(!openEdit)}
            >
              edit
            </i>
          </h2>
        )}
        

        {Object.entries(myProfile)?.map(([key, value]) => key.match(/total_/) && (
          <span key={key} className="att user-data">
            <strong>{value}</strong> {t([key])}
          </span>
        ))}

        <span className="att user-id">
          USER ID: {userId + '-' + myProfile?.id}
        </span>
      </div>
    </div>





  );
};

