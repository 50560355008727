import React from "react";
import ReactMarkdown from 'react-markdown';
import { useCat } from "../contexts/CatContext";

export default function Intro() {
  const { step , catena_front, f } = useCat();

  return (
    step < 2 && (
      <div className="wrapper">
        <ReactMarkdown>
          {catena_front}
        </ReactMarkdown>
        <a className="black" href="/rules/">{f('Full Rules')}</a>
      </div>
    )    
  )
}
