import React, { useState, useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import { useCat } from "../contexts/CatContext";
import { gameLib } from '../lib/gameLib';


export default function ChainView() {

  const { gameType, chain, setChain, chainset, setChainset, step, setStep, reset, getRandomPair, initPair, setInitPair , lang, f } = useCat();
  const [inputWord, setInputWord] = useState('');
  const [extended, setExtended] = useState(false);
  const [index, setIndex] = useState(0);
  const inputRef = useRef(null);


  const minWordLen = 2;
  const maxWordLen = 25;

  const handleInputChange = (e) => setInputWord(e.target.value);

  const handleExtend = (i) => {
    setIndex(i);
    setInputWord('');
    setExtended(!extended);
  }

  const isWordValid = (word) => {
    switch (lang) {
      case 'ru':
        return word.match(/^[а-я]+$/i);
      case 'cs':
        return word.match(/^[a-záčďéěíňóřšťúůýž]+$/i);
      case 'en':
        return word.match(/^[a-z]+$/i);
      case 'vn':
        return word.match(/\w+/i); // vn regex is not working well enough
    }
  }
  
  const addWordAtIndex = (word, i) => { // inserts word into array at index i

    if (i === 0 || i === chain.length || word === '' || word.match(/^\s+$/))
      return;
    if (word.length < minWordLen || word.length > maxWordLen) {
      toast.error(f('Word length must be between 2 and 25 characters'));
      return;
    }
    if (chain.includes(word)) {
      toast.error(f('This word is already in the chain'));
      return;
    }
    const w = DOMPurify.sanitize(word.trim().toLowerCase().replace(/\s+/g, ' '));
    if (!isWordValid(w)) {
      toast.error(f('Only English aphabet letters are allowed in this game session. '));
      return;
    }
    const newChain = [...chain];
    newChain.splice(i, 0, w);
    setChain(newChain);
    setInputWord('');
    setExtended(false);
  }

  const isChainValid = () => {
    if (chain.length < 3) {
      toast.error(f('Chain must contain at least 3 words'));
      return false;
    }
    if (chain.length > 7) {
      toast.error(f('Chain must contain no more than 7 words'));
      return false;
    }
    if (chainset.map((ch) => ch.join('')).includes(chain.join(''))) {
      toast.error(f('This chain is already in the set'));
      return false;
    }
    return true;
  }

  const addChainToSet = (chain) => {
    if (!isChainValid())
      return;

    if (chainset.length + 1 < gameLib[gameType].chainsetLen) {
      setStep(2);
      
      if ( gameLib[gameType].samePair === false ) {
        const initP = getRandomPair();
        setInitPair(initP);
        setChain(initP);
      } else {
        setChain(initPair);
      }

    } else if (chainset.length + 1 === gameLib[gameType].chainsetLen) {
      setStep(3);
      setChain([]);
    } 
    setChainset([...chainset, chain]);
  };

  const deleteWord = (i) => {
    const newChain = [...chain];
    newChain.splice(i, 1);
    setChain(newChain);
  }

  useEffect(() => {
    if (extended) {
      inputRef.current.focus();
    }
  }, [extended]);

  return (
    step > 0 && step < 4 && (
      <div className="ChainView">

        <div className="goal">
          <span><strong>{gameLib[gameType]['title']}:</strong> {gameLib[gameType].goal[lang]}</span>
          <button className="btn-small-hollow" onClick={reset}>{f('Start Afresh')}</button>
        </div>

        <div className="chain">
          {chain.length > 0 && chain?.map((word, idx) => (

            <span className={`chain__cell`} key={idx}>

              <span className={`chain__cell__show-word`}>
                {word}
                {idx !== 0 && idx !== (chain.length - 1) && (
                  <i className="material-symbols-outlined icon-button delete"
                    onClick={() => deleteWord(idx)}>
                    delete
                  </i>
                )}
              </span>

              {idx < chain.length - 1 && (

                <span className={`chain__cell__add-word ${extended && index === idx ? 'extended' : ''}`}>

                  <i className={`material-symbols-outlined icon-button ${extended && idx === index ? 'close' : step === 1 ? 'add splash' : 'add'}`} onClick={() => handleExtend(idx)}>
                    {extended && idx === index ? 'close' : 'add'}
                  </i>

                  {extended && (
                    <>
                      <input
                        ref={inputRef}
                        className={`input-word`}
                        type="text"
                        value={inputWord}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          (e.key === 'Enter') && !chain.includes(inputWord) && addWordAtIndex(inputWord, idx + 1);
                        }}
                        placeholder="Add word"
                      />

                      <i className="material-symbols-outlined icon-button enter"
                        onClick={() => addWordAtIndex(inputWord, idx + 1)}>
                        {idx === index && extended && inputWord !== '' && inputWord.length > 2 ? 'keyboard_return' : 'keyboard_return'}
                      </i>
                    </>
                  )}
                </span>
              )}
            </span>
          ))}

          {chain.length > 2 && step >= 1 && (
            <span className="button__wrapper">
              <i className="material-symbols-outlined splash round black done"
                tabIndex="0" role="button"
                onClick={() => addChainToSet(chain)}
              >
                check
              </i>
            </span>
          )}
        </div>

      </div>
    )
  )
}