import React, { useState } from "react";
import store from "store2";
import { Link, useNavigate } from "react-router-dom";
import { useCat } from "../contexts/CatContext";
import { Logo } from './Logo';
import { front } from '../lib/translate';
import './Bar.scss';


export default function Bar() {

  const { lang, handleLang, translate, handleWpLogout, reset } = useCat();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const t = (text) => translate(text, front);


  return (
    <div className="Bar">
      <div className="bar__inner">

        <div className="bar__left">
          <span className="lang">
            <select
              value={lang}
              onChange={(e) => handleLang(e.target.value)}
            >
              <option value="en">en</option>
              <option value="cs">cs</option>
              <option value="ru">ru</option>
              <option value="vn">vn</option>
            </select>
          </span>

        </div>

        <div className="bar__center">

          <span onClick={() => {
              reset();
              navigate('/');
            }
          }>
            <Logo />
          </span>

        </div>

        <div className="bar__right">


          <div className="menu-outer">
            <i className="material-symbols-outlined burger" onClick={() => setMenuOpen(!menuOpen)}> {menuOpen ? 'close' : 'menu'}</i>
            {menuOpen && (
              <div className="menu-inner">
                <span className="menu">

                  {!store.has('token') && (
                    <>
                      <span className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
                        <Link to="/">
                          {t('Home')}
                        </Link>
                      </span>

                      <span className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
                        <Link to="/login">
                          {t('Login / Register')}
                        </Link>
                      </span>
                    </>
                  )}
                  {store.has('token') && (
                    <>
                      <span className="menu-item" onClick={() => {
                          reset();
                          setMenuOpen(!menuOpen);
                          navigate('/');
                      }}>
                        {t('Home')}
                      </span>
                      <span className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
                        <Link to="/dashboard">
                          {t('Dashboard')}
                        </Link>
                      </span>
                      <span className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
                        <Link to="/rules">
                          {t('Rules')}
                        </Link>
                      </span>
                      <span className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
                        <Link to="/uses">
                          {t('Uses')}
                        </Link>
                      </span>
                      <span className="menu-item" onClick={() => {
                        setMenuOpen(!menuOpen);
                        handleWpLogout();
                        navigate('/login');
                      }}>
                        {t('Logout')}
                      </span>
                    </>
                  )}

                  {/*
                  <span className="menu-item">{m('About')}</span>
                  <span className="menu-item">{m('Register')}</span>
                  <span className="menu-item">{m('Profile')}</span>
  */}
                </span>
              </div>
            )}
          </div> {/* menu-outer */}
        </div> {/* bar__right */}
      </div> {/* bar__inner */}
    </div>
  );
}