import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Front from "./components/Front";
import Dashboard from "./components/Dashboard";
import Rules from "./components/Rules";
import Uses from "./components/Uses";
import NotFound from "./components/NotFound";
import "./App.scss";

export default function App() { 
  
 
  return (
    <>
      <div className={`app`}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Front />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/uses" element={<Uses />} />
        </Routes>
      </div>
    </>
  );
}
