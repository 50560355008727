import React from "react";
import './Avatar.scss';

export default function Avatar({src}) {

  return (
    <div className="Avatar">
      <img src={src} alt="avatar" />
    </div>
  )
}