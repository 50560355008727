import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useCat } from "../contexts/CatContext";
import Meta from "./Meta";
import Layout from "../Layout";
import MyChains from "./MyChains";
import SelectAvatar from "./SelectAvatar";
import Avatar from "./Avatar";
import { reqOptionsGet, mediaEndpoint, picsEndpoint } from "../lib/constants";
import './Dashboard.scss';
import store from "store2";



export default function Dashboard() {

  const { myProfile, updateMyProfile } = useCat();
  const initSelIm = { url: '', id: null };
  const [selectedImage, setSelectedImage] = useState(initSelIm);
  const [src, setSrc] = useState('');
  const [pictures, setPictures] = useState([]);

  const loadPictures = async () => {
    try {
      const response = await fetch(picsEndpoint + '/' + '?_embed&per_page=100', reqOptionsGet);
      const data = await response.json();
      console.log('pics', data);
      const ids = data.map((p) => p.acf.pic.id);

      const response1 = await fetch(mediaEndpoint + '/' + '?acf&_embed&include=' + ids.join(','), reqOptionsGet);
      const data1 = await response1.json();


      console.log('pics1', data1);
      const avs = data1.map((p) => ({id: p.id, url: p.source_url}));
      console.log('pics2', avs);
      setPictures(avs); 

      return true;
    } catch (error) {
      console.log(error);
    }
  };

  const saveAvatar = async () => {
    const r = updateMyProfile({ av_url: selectedImage.url, av_id: selectedImage.id });
    if (r) {
      toast.success('User updated');
      setSelectedImage({ ...initSelIm });
      setPictures([]);
      store.set('selectedImage', initSelIm);
      return true;
    }
  }

useEffect(() => {
  if (selectedImage?.url.length > 0) {
    setSrc(selectedImage.url);
  } else if (myProfile?.av_url.length > 0) {
    setSrc(myProfile.av_url);
  } 
}, [selectedImage, myProfile]);

  return (
    <Layout className="Dashboard__outer">

      <div className="Dashboard">

        <div className="header">

          <span className="avatar-edit">

            <Avatar src={src} />

            <span className="buttons">

              {(!selectedImage?.url.length > 0 && pictures.length === 0) && (
                <span className="material-symbols-outlined icon-button edit"
                  onClick={() => loadPictures()}
                >
                  edit
                </span>
              )}
              {selectedImage?.url.length > 0 && (
                <span className="material-symbols-outlined icon-button check"
                  onClick={saveAvatar}
                >
                  check
                </span>
              )}
              {(selectedImage?.url.length > 0 || pictures.length > 0) && (
                <span className="material-symbols-outlined icon-button cancel"
                  onClick={() => {
                    setSelectedImage(initSelIm);
                    store.set('selectedImage', initSelIm);
                    setPictures([]);
                  }}>
                  cancel
                </span>
              )}


            </span>

          </span>

          <Meta />

        </div>

        {pictures.length > 0 && (
          <SelectAvatar pictures={pictures} setSelectedImage={setSelectedImage} />
        )}

        <MyChains />

      </div>

    </Layout>
  );
}

