import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import banner4 from '../assets/images/ba-4.png';
import banner2 from '../assets/images/ba-2.png';

export default function Banner() {
  
  const location = useLocation();
  const [banner, setBanner] = useState('');

  useEffect(() => {
    setBanner( location.pathname === '/rules' ? banner4 : banner2 );
  }, [location]);

return (
  <div className="banner">
    <img src={banner} alt="banner" />
  </div>
)
}
