import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import store from 'store2';
import { ToastContainer } from 'react-toastify';
import { useCat } from "../contexts/CatContext";
import Layout from "../Layout";
import Bar from "./Bar";
import 'react-toastify/dist/ReactToastify.css';
import "./Login.scss";
import "../assets/scss/effects.scss";


const Login = () => {

  const { handleWpLogin, handleWpRegister, reset, setUserId, setMyProfile } = useCat();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [pwd_visible, setPwdVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(true); // login, register

  useEffect(() => {
    if (isLogin) {
      setEmail('');
    }
  }, [isLogin]);

  useEffect(() => {
    reset();
    store.clear();
    setUserId(null);
    setMyProfile(null);
  }, []);

  return (
    <Layout>
      <div className={`Login`}>
        <div className="main">
          <ToastContainer />

          <div className="login-area">
            <div className="login__secondary--wrapper">
              <Bar />
              <div className="login__secondary">
                <h1 className="logo-deco clip-text clip-text_two">Catena</h1>
                <div className="tagline">
                  <h2>For Smarter Brains</h2>
                </div>
              </div>
            </div>

            <div className="login__primary">
              <div className="form">
                {isLogin ? (
                  <h3>
                    <span>
                      Login 
                    </span>
                    <span
                      className="active-link splash" // game-button play
                      onClick={() => setIsLogin(!isLogin)}
                    >
                      Register
                    </span>
                  </h3>
                ) : (
                  <h3>
                    <span
                      className="active-link splash"
                      onClick={() => setIsLogin(!isLogin)}
                    > 
                    Login
                  </span>
                  <span>
                    Register
                  </span>

                </h3>
                )}
                {!isLogin && (
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="user-input"
                />
                )}
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  className="user-input"
                />
                <span>
                  <input
                    type={pwd_visible ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="user-input"
                  />
                  <i
                    onClick={() => setPwdVisible(!pwd_visible)}
                    className="material-symbols-outlined eye">visibility{pwd_visible ? '_off' : ''}
                  </i>
                </span>
                <span className="wrapper">
                  <input
                    type="submit"
                    className="login__button"
                    onClick={ async () => {
                        if (isLogin) {
                          const r = await handleWpLogin(username, password);
                          r === true && navigate('/');
                        } else {
                          const r = await handleWpRegister(username, password, email);
                          r === true && navigate('/');
                        }
                      }}
                    value={ isLogin ? 'Login' : 'Register' }
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;

