import React from "react";
import './Neono.scss'

export default function Neono ({container = 'wholescreen'}) {

  return (
    container === 'wholescreen' ?
    <div className="neon--wrapper">
      <div className="neon">
        <div className="neon--spinner"></div>
      </div>  
    </div>
    :
    <div className="neon">
    <div className="neon--spinner"></div>
  </div>  
  )
}
